import { FavoriteBorderOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";

import { cardStyle } from "../../styles/card";
import { ListCategory } from "../common/ListCategory";

import HeartOutlinedDark from "../../assets/icon/heart-outlineDark2.svg";
import HeartFilled from "../../assets/icon/heartfilled.svg";
import React, { useRef, useState } from "react";
import img1 from "../../assets/images/Rectangle 4.png";
import img2 from "../../assets/images/Rectangle 5.png";
import img3 from "../../assets/images/Rectangle 3.png";
import img4 from "../../assets/images/Rectangle 2.png";
import grapeAvatar from "../../assets/icon/circleGrape.png"
import { useRouter } from "next/router";
import Image from "next/image";

function HomeGrapeOfficailCard({ handleListFavorite,curation,category = null }) { 
  const router = useRouter();
  const clickInsideCard = useRef(null);
	const clickOnFavButton = useRef(null);
  const style = cardStyle;
  const favoriteClickHandler = (e) => {
		if(clickInsideCard.current.contains(e.target) && clickOnFavButton.current.contains(e.target)){
			if(category!=null){
				handleListFavorite(curation.id,category)
			}else{
				handleListFavorite(curation.id)
			}			
		}		
	};
  const handleClick = (e) => {
		if(clickInsideCard.current.contains(e.target) && !clickOnFavButton.current.contains(e.target)){
		if (curation.list_type === "community_list") {
			router.push(`/curation/${curation.slug}`);
		} else {
			router.push(`/list/${curation.slug}`);
		}
		}
	};
  
  return (
    <Card
    ref={clickInsideCard}
    onClick={handleClick}
      sx={{
        ...style.card,
        marginBottom: 2,
        boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
      }}
    >
      <CardContent>
        <Typography variant="body1" mb={1}>
         {curation.title}
        </Typography>
        <Grid
          container
          columnSpacing={"1px"}
          borderRadius={2}
          sx={{ overflow: "hidden", position: "relative" }}
        >
           
          {curation?.products_details?.map((product, i)=>{
            return <Grid item xs={3} key={`products-${i}`}>
                  <Image
                    alt={product.name}
                    src={product.image.file}
                    height={120}
                    width={100}
                    // sx={{
                    //   height: 120,
                    //   width: "100%",                     
                    // }}
                    
                  >              
                  </Image>
                
                </Grid>
          })}
          
           <Box
                sx={{
                  position: "absolute",
                  bottom: 12,
                  left: 5,                
                  width:"80%",
                }}
               
              > 
              {curation.list_category.length > 0 && curation.list_category
											.slice(0, 1).map((category, i)=>{
                        return <Box  key={`listCategory-${i}`} sx={{padding:"2.5px", display:"inline-block"}}>
                              <ListCategory                                    
                                      color_code={
                                        category.color_code
                                      }
                                      title={
                                        category.name
                                      }
                                    />
                              </Box>
                      })}                
            </Box>

           {curation.products.length-4 >0 &&
            <Box sx={{
                  background: "rgba(0, 0, 0, 0.5)",
                  borderRadius:8,
                  position:"absolute",
                  bottom:12,
                  right:5,
                  paddingX:"5px",
                  height:"20px",
                  display:"flex",
                  alignItems:"center"
              }}>              
                  <Typography variant="smallText" color={"background.default"} >+{curation.products.length-4} more</Typography>                
              </Box>
            }
        </Grid>
      </CardContent>
      <CardHeader
        sx={{
          textAlign: "left",
          "& .MuiCardHeader-avatar": {
            marginRight: "4px",
          },
          paddingTop: "0px",
          paddingRight: "25px",
          "& .MuiCardHeader-action": {
            margin: 0,
          },
          
        }}
        titleTypographyProps={{ variant: "body2",style:{opacity:.75} }}
        avatar={
          <Avatar
            sx={{ width: 20, height: 20, fontSize: 12 }}
            aria-label="profile"
            src={curation.owner?.image?.file}
           
          >
            
            {curation.owner?.name.slice(0,1)}
          </Avatar>
        }
        action={
          <Stack direction="row">
            <Collapse orientation="horizontal" in={true}>
              <Typography
                variant="body2"
                color={"primary"}
                sx={{ marginRight: "8px" }}
              >
               {curation.favourites_count>0?curation.favourites_count:''}
              </Typography>
            </Collapse>
            <IconButton
              aria-label="settings"
              sx={{ height: "18px", width: "19px" }}
              onClick={favoriteClickHandler}
              ref={clickOnFavButton}
            >
              <img src={curation.user_list_favourites?.favourite?HeartFilled:HeartOutlinedDark} />
            </IconButton>
          </Stack>
        }
        title={curation.owner?.name}
      />
    </Card>
  );
}

export default HomeGrapeOfficailCard;
