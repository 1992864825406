import { List, Paper, Stack } from "@mui/material";

import { Category } from "./Category";

export const CategoryList = ({ categoryList }) => {
  return (
    <List
      direction="row"
      component={Stack}
      spacing={2}
  
      sx={{
        overflowX: "scroll",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          width: 0,
        },
      }}
    >
      {categoryList.map((item) => (
        <Category category={item} key={item.id} />
      ))}
    </List>
    //   </ImageList>
  );
};
