import { Avatar, ImageListItem, ImageListItemBar } from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import { useRouter } from "next/router";

export const Category = ({ category, isProductCategory = false }) => {
	let router = useRouter();
	const handleClick = () => {
		if (isProductCategory) {
			router.push(`/product-category/${category.id}`);
		} else {
			router.push(`/category/${category.id}`);
		}
	};
	return (
		<Box onClick={handleClick} alignItems={'center'}>
			<ImageListItem sx={{ borderRadius: "50%" }} key={category.img}>
				<Avatar sx={{ width: 98, height: 98, margin:'auto' }}>
					<Image
						src={category.logo?.file}
						alt={category.name}
						width={98}
						height={98}
					/>
				</Avatar>

				<ImageListItemBar
					position="below"
					sx={{
						textAlign: "center",
						fontVariant: "body2",
						".MuiImageListItemBar-title": {
							fontSize: "14px",
						},
					}}
					title={category.name}
					// subtitle={category.author}
				/>
			</ImageListItem>
		</Box>
	);
};
